import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="cover">
      <img src="https://cdn.prod.website-files.com/62be13fdb8a06d0f7cf4aa7b/62cedce23075eeefa4391c3d_cloud1.png" loading="eager" alt="" className="cloud-1 cloud-1-1"></img>
      <img src="https://cdn.prod.website-files.com/62be13fdb8a06d0f7cf4aa7b/62cedce23075eeefa4391c3d_cloud1.png" loading="eager" alt="" className="cloud-1 cloud-1-2"></img>
      <img src="https://cdn.prod.website-files.com/62be13fdb8a06d0f7cf4aa7b/62cedd2a8d68d86bc3433530_cloud3.png" loading="eager" alt="" className="cloud-2 cloud-2-1"></img>
      <img src="https://cdn.prod.website-files.com/62be13fdb8a06d0f7cf4aa7b/62cedd2a8d68d86bc3433530_cloud3.png" loading="eager" alt="" className="cloud-2 cloud-2-2"></img>
      <img src="https://cdn.prod.website-files.com/62be13fdb8a06d0f7cf4aa7b/62cedd0333b4d08248813ec8_cloud2.png" loading="eager" alt="" className="cloud-2 cloud-2-3"></img>
      <img src="https://cdn.prod.website-files.com/62be13fdb8a06d0f7cf4aa7b/62cedd0333b4d08248813ec8_cloud2.png" loading="eager" alt="" className="cloud-3 cloud-3-1"></img>
      <img src="https://cdn.prod.website-files.com/62be13fdb8a06d0f7cf4aa7b/62cedd0333b4d08248813ec8_cloud2.png" loading="eager" alt="" className="cloud-3 cloud-3-2"></img>
      <div className="header">
      <a href="/" aria-current="page" class="w-inline-block w--current"><img src="https://cdn.prod.website-files.com/62be13fdb8a06d0f7cf4aa7b/62c35e55114767823970008c_logo%402x.png" loading="eager" width="325" alt="" class="logo"/></a>
        </div>
      <h1 className="heading"><strong>Explore a New Type of upgraded gameplay</strong></h1>
      <p className="paragraph-3">Make your home in a world of unlimited adventure. Master skills and play with friends. Build new communities.
        <br/>Beta out! Autocrafting and more!
      </p>
      <div className="cta">
  <a href="PixelsXYZPro.apk" download="PixelsXYZPro.apk" className="pixel-button button-hero" >
    <strong className="pixel-button-label">Download APK&nbsp;Free</strong>
  </a>
</div>

      </div>
  );
}

export default App;

